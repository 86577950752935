import * as React from "react";
import PageBody from "@/components/PageBody";
import H2 from "@/components/Headings/H2";
import Paragraph from "@/components/Paragraph";
import Centered2x2Grid from "../../components/Centered2x2grid";
import { privateLesson as leadFormFields } from "../../config/ContactForm.data";
import Layout from "../../components/Layout";
import { createSeoData } from "../../utils/page";

const features = [
  {
    name: "Customized Lessons",
    description:
      "Having a lesson based on your learning style and ability gives you a great advantage. There is also the advantage of making you feel more responsible for your education and thus more likely to prepare, since you can’t rely on classmates to have the answers.",
    icon: "AdjustmentsIcon",
  },
  {
    name: "1-to-1 teaching",
    description:
      "You will have the teacher’s full attention, which will provide you with the best tutorship. Furthermore, your tutor can help you more directly with your homework, practice, and assessments.",
    icon: "UsersIcon",
  },
  {
    name: "Flexibility",
    description:
      "Private tutors are generally more flexible in schedule. Group lessons need to accommodate the schedule to work for a larger number of students, which may make learning less convenient for you.",
    icon: "ClockIcon",
  },
  {
    name: "Environment",
    description:
      "You are going to have a focused learning environment with less distractions going on than in a group learning environment. Furthermore, you will have more time to develop a relationship with your tutor and shape the class to your particular interests.",
    icon: "AcademicCapIcon",
  },
];

export default function PrivateLessons() {
  const seo = createSeoData({
    title: "Private Language Lessons",
    description:
      "Schedule a private language lesson with our expert language tutors. 1 on 1 live language courses are one of the most practical ways to learn a language quickly. Schedule a lesson today!",
  });

  const hero = {
    heroTitleLine1: "Learn a language with a",
    heroTitleLine2: "Private lesson",
  };

  return (
    <Layout seo={seo} hero={hero} leadFormFields={leadFormFields}>
      <PageBody limitMaxWidth>
        <H2 title="Are you looking to greatly improve your language skills?" />
        <Paragraph>
          Are you looking to greatly improve your language skills? Then private
          lessons are the answer for you! With specialized attention to your
          individualized language learning needs in a one-on-one environment to
          help you swiftly overcome barriers in your language learning and to
          excel into the next level!
        </Paragraph>
        <Paragraph>
          Private lessons guarantee that you receive 100% of a tutor’s
          attention, providing an excellent environment for learning as you will
          be able to ask as many questions as you want and review as much or as
          little as you need. You can receive great advice, expert instruction,
          and tailored learning strategies from Lingua Fonica’s qualified
          tutors.
        </Paragraph>
      </PageBody>
      <PageBody>
        <Centered2x2Grid
          title="What you can gain from private lessons with a tutor"
          subtitle="A better way to learn a language"
          features={features}
        />
      </PageBody>
      <PageBody limitMaxWidth>
        <Paragraph>
          To conclude, having private lessons from a qualified tutor is going to
          provide you with the best learning environment in most aspects of
          language learning. Learning with a tutor here on Lingua Fonica is
          going to help you reach your language learning goals in great leaps
          and bounds.
        </Paragraph>
        <Paragraph>
          However, if you prefer learning in a group environment with peers,
          then make sure to check out our{" "}
          <a
            href="/lessons/group"
            className="underline font-medium text-indigo-600 hover:text-indigo-500"
          >
            {" "}
            group lessons
          </a>
          !
        </Paragraph>
      </PageBody>
    </Layout>
  );
}
